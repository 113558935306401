const NavItems = () => {
  return (
    <ul className="footer-links">
      <li className="link">
        <a href="/about">About</a>
      </li>
      <li className="link">
        <a href="/case-studies">Case Studies</a>
      </li>
      <li className="link">
        <a href="/about#mail-sent">Schedule a Demo</a>
      </li>
      <li className="link">
        <a href="/privacy-policy">Privacy Policy</a>
      </li>
    </ul>
  );
};

export default function Footer() {
  return (
    <footer className="main-footer-section">
      <section className="main-container footer">
        <article className="content-wrapper">
          <figure className="logo-container">
            <img
              src="https://storage.googleapis.com/fk-gp/websiteAssets/fankave/navbar/fankave-icon.webp"
              alt="fankave-logo"
            />
          </figure>

          <NavItems />

          <p className="copyright">© FanKave. 2024. All rights reserved</p>
        </article>
      </section>
    </footer>
  );
}
