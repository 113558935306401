import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import Button from "components/base/button";

export default function Navbar() {
  const [scroll, setScroll] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const { pathname } = useRouter();
  const [currentPage, setCurrentPage] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });

    setCurrentPage(pathname.split("/")[1]);
  }, [pathname]);

  return (
    <header className={`header ${scroll && "scroll-active"}`}>
      <nav className={`navbar main-container `}>
        <main className="nav-main-container">
          <section className="logo-with-hamburger">
            <a href="/">
              <figure className="logo-container">
                <img
                  src="https://storage.googleapis.com/fk-gp/websiteAssets/fankave/navbar/fankave-logo.webp"
                  alt="fankave logo"
                />
              </figure>
            </a>
            <div
              tabIndex={0}
              className={`hamburger ${!navOpen ? "--open" : "--close"}`}
              onClick={() => setNavOpen(!navOpen)}
              onBlur={() => setNavOpen(false)}
            >
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
          </section>
          <ul className={`nav-items ${navOpen ? "--open" : "--close"}`}>
            <li
              className={`nav-item ${currentPage == "about" && "nav-active"}`}
            >
              <a href="/about">About</a>
            </li>
            <li
              className={`nav-item ${
                currentPage == "case-studies" && "nav-active"
              }`}
            >
              <a href="/case-studies">Case Studies</a>
            </li>
            <li className="nav-item --button">
              <Button link="/about#mail-sent" text="Schedule a demo" filled />
            </li>
          </ul>
        </main>
      </nav>
    </header>
  );
}
